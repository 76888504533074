import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { websiteName } from "../../config/webConfig";
import { useProjects } from "../../firebase/fbQueries";
import ProjectBoxLoad from "../common/ProjectBoxLoad";
import ProjectBox from "../common/ProjectBox";

function Projects() {
  const projects = useProjects(db);

  return (
    <>
      <Helmet>
        <title>Proyectos - {websiteName}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h1>Proyectos</h1>
            <span className="line"></span>
            <p>
              Explora los proyectos en los que he trabajado y conoce mis
              resultados.
            </p>
          </div>
          <div className="grid">
            {projects.length === 0 ? (
              <>
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
                <ProjectBoxLoad />
              </>
            ) : (
              projects
                .filter((project) => project.show)
                .sort((a, b) => a.label - b.label)
                .map((project, index) => (
                  <ProjectBox key={index} {...project} />
                ))
            )}
          </div>
        </div>
      </section>
    </>
  );
}
export default Projects;
