import { useEffect, useState } from "react";
import TopBar from "../common/TopBar";
import Header from "../common/Header";
import Main from "../common/Main";
import Footer from "../common/Footer";
import Menu from "../common/Menu";
import Glass from "../common/Glass";
import ScrollToTop from "../common/ScrollToTop";

function Layout() {
  const [menu, setMenu] = useState(false);

  const handleMenu = () => {
    setMenu(!menu);
    setGlass(!glass);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.code === "KeyM" && event.altKey) {
        handleMenu();
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  });

  const [glass, setGlass] = useState(false);

  const handleGlass = () => {
    setGlass(!glass);
    setMenu(false);
  };

  return (
    <div className={!menu ? "app" : "app no-scroll"}>
      <ScrollToTop />
      <TopBar />
      <Header menu={menu} handleMenu={handleMenu} />
      <Menu menu={menu} handleMenu={handleMenu} />
      <Main />
      <Footer />
      <Glass glass={glass} handleGlass={handleGlass} />
    </div>
  );
}
export default Layout;
