import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavMenu from "./NavMenu";
import {
  faArrowUpRightFromSquare,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import { useLinks } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function Menu(props) {
  const links = useLinks(db);

  return (
    <div className={!props.menu ? "menu" : "menu show"}>
      {props.menu && (
        <>
          <div className="menu-header">
            <div className="container">
              <span className="menu-title">Menú de navegación</span>
              <button
                type="button"
                className="button icon"
                onClick={props.handleMenu}
              >
                <FontAwesomeIcon icon={faClose} size="lg" />
              </button>
            </div>
          </div>
          <div className="menu-content">
            <NavMenu click={props.handleMenu} />
            <div className="nav-menu">
              <ul className="nav-list">
                {links.map((link, index) => (
                  <li
                    className="nav-item"
                    onClick={props.handleMenu}
                    key={index}
                  >
                    <a
                      href={link.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="nav-link"
                    >
                      {link.text}
                      <FontAwesomeIcon
                        icon={faArrowUpRightFromSquare}
                        size="sm"
                      />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default Menu;
