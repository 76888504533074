import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function NavMenu(props) {
  const { t } = useTranslation();

  const Navigate = useNavigate();

  const actionLink = () => {
    Navigate("/contratar");
  };

  return (
    <nav className="nav-icons">
      <ul className="nav-list">
        <li>
          <button
            type="button"
            className="button action"
            aria-label="Crear un pedido personalizado"
            onClick={actionLink}
          >
            Contratar
          </button>
        </li>
        <li>
          <button
            type="button"
            className="button icon"
            onClick={props.handleMenu}
            title={t("title.menu")}
            aria-label={
              !props.menu ? t("button.menu.open") : t("button.menu.close")
            }
          >
            <FontAwesomeIcon icon={!props.menu ? faBars : faClose} size="lg" />
          </button>
        </li>
      </ul>
    </nav>
  );
}
export default NavMenu;
