import {
  faFacebook,
  faGithub,
  faInstagram,
  faLinkedin,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function SocialMedia() {
  return (
    <ul className="socials-list">
      <li className="social-item">
        <a
          href="https://www.instagram.com/esdanielvargas"
          target="_blank"
          rel="noopener noreferrer"
          title="Instagram"
          className="social-link"
        >
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </a>
      </li>
      <li className="social-item">
        <a
          href="https://www.facebook.com/esdanielvargas"
          target="_blank"
          rel="noopener noreferrer"
          title="Facebook"
          className="social-link"
        >
          <FontAwesomeIcon icon={faFacebook} size="lg" />
        </a>
      </li>
      <li className="social-item">
        <a
          href="https://www.twitter.com/intent/follow?screen_name=esdanielvargas"
          target="_blank"
          rel="noopener noreferrer"
          title="Twitter"
          className="social-link"
        >
          <FontAwesomeIcon icon={faTwitter} size="lg" />
        </a>
      </li>
      <li className="social-item">
        <a
          href="https://www.youtube.com/esdanielvargas?sub_confirmation=1"
          target="_blank"
          rel="noopener noreferrer"
          title="YouTube"
          className="social-link"
        >
          <FontAwesomeIcon icon={faYoutube} size="lg" />
        </a>
      </li>
      <li className="social-item">
        <a
          href="https://www.tiktok.com/@esdanielvargas"
          target="_blank"
          rel="noopener noreferrer"
          title="TikTok"
          className="social-link"
        >
          <FontAwesomeIcon icon={faTiktok} size="lg" />
        </a>
      </li>
      <li className="social-item">
        <a
          href="https://www.linkedin.com/in/esdanielvargas"
          target="_blank"
          rel="noopener noreferrer"
          title="LinkedIn"
          className="social-link"
        >
          <FontAwesomeIcon icon={faLinkedin} size="lg" />
        </a>
      </li>
      <li className="social-item">
        <a
          href="https://www.github.com/esdanielvargas"
          target="_blank"
          rel="noopener noreferrer"
          title="GitHub"
          className="social-link"
        >
          <FontAwesomeIcon icon={faGithub} size="lg" />
        </a>
      </li>
    </ul>
  );
}
export default SocialMedia;
