import { faEnvelope, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function FlexAction() {
  const { t } = useTranslation();
  return (
    <div className="flex-action">
      <button
        type="button"
        className="button top"
        onClick={() =>
          window.open("https://www.paypal.me/esdanielvargas", "_blank")
        }
      >
        <span>
          <FontAwesomeIcon icon={faStar} />
        </span>
        <span>{t("button.support")}</span>
      </button>
      <button
        type="button"
        className="button top"
        onClick={() =>
          window.open(
            "https://mail.google.com/mail/?fs=1&tf=cm&source=mailto&to=hello@danielvargas.dev",
            "_blank"
          )
        }
      >
        <span>
          <FontAwesomeIcon icon={faEnvelope} />
        </span>
        <span>{t("button.contact")}</span>
      </button>
    </div>
  );
}
export default FlexAction;
