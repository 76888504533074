import { Link } from "react-router-dom";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function PricingBox(props) {
  return (
    <>
      {props.show && (
        <div className="pricing-box">
          <div className="wrapper">
            <span className="title">{props.title}</span>
            {props.price && (
              <span className="price">
                ${props.price.toFixed(2)} USD
                {props.hour && <>/h</>}
              </span>
            )}
            <span className="line"></span>
            {props.features && (
              <ul className="features">
                <li>
                  <b>Incluye: </b>
                </li>
                {props.features.map((item, index) => (
                  <li key={index}>
                    <span className="icon">
                      <FontAwesomeIcon
                        icon={faCheck}
                        color="green"
                        size="sm"
                      />
                    </span>
                    <span className="text">{item}</span>
                  </li>
                ))}
                {props.does && (
                  <>
                    <li>
                      <hr />
                    </li>
                    <li>
                      <b>No incluye: </b>
                    </li>
                    {props.does.map((item, index) => (
                      <li key={index}>
                        <span className="icon">
                          <FontAwesomeIcon
                            icon={faClose}
                            color="firebrick"
                            size="sm"
                          />
                        </span>
                        <span className="text">{item}</span>
                      </li>
                    ))}
                  </>
                )}
              </ul>
            )}
            <>
              <Link
                to={`/precios/${props.path}`}
                className="button ghost"
              >
                Más Información
              </Link>
            </>
          </div>
        </div>
      )}
    </>
  );
}
export default PricingBox;
