function Glass(props) {
  return (
    <div
      className={!props.glass ? "glass" : "glass show"}
      onClick={props.handleGlass}
    >
    </div>
  );
}
export default Glass;
