import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useMessages } from "../../firebase/fbQueries";
import { db } from "../../firebase/config";

function FlexMessages() {
  const messages = useMessages(db);
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentMessage((prevMessage) => (prevMessage + 1) % messages.length);
    }, 5000);
    return () => clearInterval(interval);
  });

  // const messages = [
  //   {
  //     text: "Welcome to my website, powered by React.",
  //     path: ""
  //   },
  //   {
  //     text: "Discover all the services available.",
  //     path: "/services"
  //   },
  //   {
  //     text: "Quote prices of projects and services.",
  //     path: "/pricing"
  //   },
  //   {
  //     text: "Explore all projects developed by me.",
  //     path: "/projects"
  //   },
  //   {
  //     text: "Contact me if you need a custom website.",
  //     path: "/contact"
  //   },
  // ];

  return (
    <div className="flex-messages">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`message ${currentMessage === index ? "show" : ""}`}
        >
          {message.path ? (
            <Link to={"/"+message.path}>{message.text}</Link>
          ) : (
            <span>{message.text}</span>
          )}
        </div>
      ))}
    </div>
  );
}
export default FlexMessages;
