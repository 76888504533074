import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/firestore";
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCw-NHsbAVK6GZkQJ39-T4ngSjctmkaxuk",
    authDomain: "esdanielvargas.firebaseapp.com",
    databaseURL: "https://esdanielvargas-default-rtdb.firebaseio.com",
    projectId: "esdanielvargas",
    storageBucket: "esdanielvargas.appspot.com",
    messagingSenderId: "609992314200",
    appId: "1:609992314200:web:247625343d16c8e619292d",
    measurementId: "G-5S47XFE562"
  };

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);

export { db, storage, analytics };