import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import { useServices } from "../../firebase/fbQueries";

import ServiceItem from "../common/ServiceItem";
import { websiteName } from "../../config/webConfig";

function Services() {
  const services = useServices(db);

  console.log(services);

  return (
    <>
      <Helmet>
        <title>Services - {websiteName}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h1>Servicios</h1>
            <span className="line"></span>
            <p>
              Descubre los servicios que ofrezco para mejorar la presencia en
              línea de tu negocio.
            </p>
          </div>
          {services.length === 0 ? (
            <>Cargando...</>
          ) : (
            services
              .sort((a, b) => a.order - b.order)
              .filter((service) => service.show)
              .map((service, index) => <ServiceItem key={index} {...service} />)
          )}
        </div>
      </section>
    </>
  );
}
export default Services;
