import SocialMedia from "./SocialMedia";

function RowSocials() {
  return (
    <div className="row-socials">
      <SocialMedia />
    </div>
  );
}
export default RowSocials;
