import { Helmet } from "react-helmet";
import { db } from "../../firebase/config";
import PricingBox from "../common/PricingBox";
import { usePricing } from "../../firebase/fbQueries";
import { websiteName } from "../../config/webConfig";

function Pricing() {
  const pricing = usePricing(db);

  return (
    <>
      <Helmet>
        <title>Precios - {websiteName}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h1>Precios</h1>
            <span className="line"></span>
            <p>
              Consulta los precios competitivos de nuestros servicios
              personalizados.
            </p>
          </div>
          <div className="grid">
            {pricing
              .sort((a, b) => a.order - b.order)
              .map((price, index) => (
                <PricingBox key={index} {...price} />
              ))}
          </div>
        </div>
      </section>
    </>
  );
}
export default Pricing;
