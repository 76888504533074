import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

function RowCopyright() {
  const { t } = useTranslation();
  const year = new Date().getFullYear();
  return (
    <div className="row-copyright">
      <span>
        <FontAwesomeIcon icon={faCopyright} />
      </span>
      <span>
        {year + " Daniel Vargas ~ "}
        {t("footer.copyright")}
      </span>
    </div>
  );
}
export default RowCopyright;
