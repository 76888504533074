import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Layout from "./components/pages/Layout";
import "./assets/style/app.css";
import Home from "./components/pages/Home";
import Pricing from "./components/pages/Pricing";
import Services from "./components/pages/Services";
import ServicePage from "./components/pages/ServicePage";
import Projects from "./components/pages/Projects";
import CustomProject from "./components/pages/CustomProject";
import { Faqs } from "./components/pages/Faqs";
import { Contact } from "./components/pages/Contact";
import { PricingPage } from "./components/pages/PricingPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Home />} />

          <Route path="/services" element={<Services />} />
          <Route path="/services/:path" element={<ServicePage />} />

          <Route path="/servicios" element={<Services />} />
          <Route path="/servicios/:path" element={<ServicePage />} />

          <Route path="/prices" element={<Pricing />} />
          <Route path="/prices/:path" element={<Pricing />} />

          <Route path="/precios" element={<Pricing />} />
          <Route path="/precios/:path" element={<PricingPage />} />

          <Route path="/projects" element={<Projects />} />
          <Route path="/projects/:path" element={<Projects />} />

          <Route path="/proyectos" element={<Projects />} />
          <Route path="/proyectos/:path" element={<Projects />} />

          <Route path="/contact" element={<Contact />} />
          <Route path="/contacto" element={<Contact />} />

          <Route path="/faqs" element={<Faqs />} />
          <Route path="/custom" element={<CustomProject />} />
          <Route path="/contratar" element={<CustomProject />} />

          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/home" element={<Navigate to="/" />} />
          <Route path="/index" element={<Navigate to="/" />} />
          <Route path="/inicio" element={<Navigate to="/" />} />
          <Route path="/servicios" element={<Navigate to="/services" />} />
          <Route path="/precios" element={<Navigate to="/prices" />} />
          <Route path="/pricing" element={<Navigate to="/prices" />} />
          <Route path="/proyectos" element={<Navigate to="/projects" />} />
          <Route path="/contacto" element={<Navigate to="/contact" />} />
          <Route
            path="/preguntas-frecuentes"
            element={<Navigate to="/faqs" />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
