import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ProjectBox(props) {
  return (
    <>
      {props.show && (
        <div className="project-box">
          <div className="project-box-picture">
            {props.image && (
              <img
                src={props.image}
                className="project-box-image"
                alt={"Vista previa del sitio web, " + props.title + "."}
                loading="lazy"
              />
            )}
            <img
              src="https://firebasestorage.googleapis.com/v0/b/esdanielvargas.appspot.com/o/projects%2Fisotipo.svg?alt=media&token=b4a0cb21-7cb4-465f-b793-a9daa032e07b"
              alt="Imagén por defecto"
              className="projects-box-default"
              loading="lazy"
            />
          </div>
          <div className="project-box-data">
            <span
              className="project-box-title"
              role="heading"
              aria-level={props.title}
            >
              {props.title}
            </span>
            <span
              className="project-box-label"
              role="heading"
              aria-level={props.label}
            >
              {props.label}
            </span>
            {props.link && (
              <a
                href={"https://" + props.link}
                target="_blank"
                rel="noopener noreferrer"
                className="button primary-button"
                aria-label="Explorar Proyecto"
              >
                Explorar Proyecto
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="sm" />
              </a>
            )}
          </div>
        </div>
      )}
    </>
  );
}
export default ProjectBox;
