import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { useEffect } from "react";

function CustomProject() {
  const { t } = useTranslation();

  const { register, handleSubmit, watch, setValue } = useForm();

  const tipoProyecto = watch("tipoProyecto");

  useEffect(() => {
    if (tipoProyecto === "landingPage") {
      setValue("hosting", "1GB");
    }
  }, [tipoProyecto, setValue]);

  const onSubmit = (data) => {
    // Manejar el envío de datos
    console.log(data);
  };

  return (
    <>
      <Helmet>
        <title>
          {t("title.custom")} - {websiteName}
        </title>
      </Helmet>
      <section className="section">
        <div className="container">
          <div className="title">
            <h1>{t("title.custom")}</h1>
          </div>
          <div className="content">
            <form onSubmit={handleSubmit(onSubmit)} className="form">
              <div className="form-block">
                <input
                  type="text"
                  placeholder="Nombres"
                  {...register("firstName")}
                />
                <input
                  type="text"
                  placeholder="Apellidos"
                  {...register("lastName")}
                />
              </div>
              <div className="form-block">
                <input
                  type="text"
                  placeholder="Ingrese su correo electrónico"
                  {...register("email")}
                />
              </div>
              <div className="form-block">
                <select>
                  <option value=""></option>
                </select>
              </div>
              <div className="form-block">
                <select {...register("website")}>
                  <option value="Landing page">Landing Page - $250</option>
                  <option value="Basic Website">Sitio Web Básico - $350</option>
                  <option value="Standard Website">
                    Sitio Web Standard - $450
                  </option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} size="sm" />
              </div>
              <div className="form-block">
                <select name="" id="">
                  <option value="">Hosting 1GB - $5/mes</option>
                  <option value="">Hosting 2GB - $10/mes</option>
                  <option value="">Hosting 4GB - $15/mes</option>
                  <option value="">Hosting 8GB - $20/mes</option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} size="sm" />
              </div>
              <div className="form-block">
                <select>
                  <option value="">Diseño #1 - $8</option>
                  <option value="">Diseño #2 - $12</option>
                  <option value="">Diseño #3 - $20</option>
                  <option value="">Diseño Personalizado - $50</option>
                </select>
                <FontAwesomeIcon icon={faAngleDown} size="sm" />
              </div>
              <button
                type="submit"
                className="button primary-button"
                aria-label="Enviar pedido personalisado"
              >
                Enviar Pedido Personalisado
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
export default CustomProject;
