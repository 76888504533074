import { collection, doc, onSnapshot } from "firebase/firestore";
import { useState, useEffect } from "react";

export function useMessages(db) {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const messagesRef = collection(db, "messages");

    const unsubscribe = onSnapshot(messagesRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setMessages(data);
    });

    return () => unsubscribe();
  }, [db]);

  return messages;
}

export function useLinks(db) {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const linksRef = collection(db, "links");

    const unsubscribe = onSnapshot(linksRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setLinks(data);
    });

    return () => unsubscribe();
  }, [db]);

  return links;
}

export function useServices(db) {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const servicesRef = collection(db, "services");

    const unsubscribe = onSnapshot(servicesRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setServices(data);
    });

    return () => unsubscribe();
  }, [db]);

  return services;
}

export function usePricing(db) {
  const [pricing, setPricing] = useState([]);

  useEffect(() => {
    const pricingRef = collection(db, "pricing");

    const unsubscribe = onSnapshot(pricingRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setPricing(data);
    });

    return () => unsubscribe();
  }, [db]);

  return pricing;
}

export function useProjects(db) {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const projectsRef = collection(db, "projects");

    const unsubscribe = onSnapshot(projectsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });
      setProjects(data);
    });

    return () => unsubscribe();
  }, [db]);

  return projects;
}

export function useWebsiteConfig(db) {
  const [websiteConfig, setWebsiteConfig] = useState([]);

  useEffect(() => {
    const configWebsiteRef = doc(db, "settings", "website");

    const unsubscribe = onSnapshot(configWebsiteRef, (doc) => {
      if (doc.exists()) {
        setWebsiteConfig({ ...doc.data(), id: doc.id });
      } else {
        console.log("No such document!");
      }
    });
    return () => unsubscribe();
  }, [db]);

  return websiteConfig;
}