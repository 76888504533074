import RowCopyright from "./RowCopyright";
import RowSocials from "./RowSocials";

function Footer() {
  return (
    <footer className="footer">
      <div className="container">
        <RowSocials />
        {/* <hr /> */}
        <RowCopyright />
      </div>
    </footer>
  );
}
export default Footer;
