function ProjectBoxLoad() {
  return (
    <div className="project-box-load">
        <div className="project-box-load-image"></div>
        <div className="project-box-load-data">
            <span className="title"></span>
            <div className="label"></div>
            <div className="button"></div>
        </div>
    </div>
  )
}
export default ProjectBoxLoad