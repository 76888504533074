import { Helmet } from "react-helmet";
import { websiteName } from "../../config/webConfig";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope, faTrashCan } from "@fortawesome/free-regular-svg-icons";

export const Contact = () => {
  const { register, handleSubmit } = useForm();

  const onSubmit = () => {
    alert("Mensaje enviado exitosamente.");
  };

  return (
    <>
      <Helmet>
        <title>Contacto - {websiteName}</title>
      </Helmet>

      <section className="section">
        <div className="container">
          <div className="row-caption">
            <h1>Contacto</h1>
            <span className="line"></span>
            <p>
              Contáctame para obtener más información y comenzar tu proyecto.
            </p>
          </div>
          <div className="content">
            <form className="form" onSubmit={handleSubmit(onSubmit)}>
              <div className="block">
                <input
                  type="text"
                  placeholder="Nombre"
                  {...register("firstName", { required: true })}
                />
                <input
                  type="text"
                  placeholder="Apellido"
                  {...register("lastName", { required: true })}
                />
              </div>
              <div className="block">
                <input
                  type="email"
                  placeholder="Correo electrónico"
                  {...register("email", { required: true })}
                />
              </div>
              <div className="block">
                <input
                  type="text"
                  placeholder="Asunto"
                  {...register("subject", { required: true })}
                />
              </div>
              <div className="block">
                <textarea
                  placeholder="Mensaje..."
                  cols="30"
                  rows="8"
                  {...register("message", { required: true })}
                ></textarea>
              </div>
              <div className="block">
                <a
                  href="mailto:hello@danielvargas.dev"
                  className="button icon"
                  title="Abrir el correo electrónico"
                  aria-label=""
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
                <div className="group">
                  <button
                    type="reset"
                    className="button reset"
                    title="Eliminar formulario"
                    aria-label="Eliminar formulario"
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                  <button
                    type="submit"
                    className="button submit"
                    aria-label="Enviar formulario"
                  >
                    Enviar
                    <FontAwesomeIcon icon={faPaperPlane} size="sm" />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
};
