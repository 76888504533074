import FlexAction from "./FlexAction";
import FlexMessages from "./FlexMessages";
import FlexSocials from "./FlexSocials";

function TopBar() {
  return (
    <div className="top-bar">
      <div className="container">
        <FlexAction />
        <FlexMessages />
        <FlexSocials />
      </div>
    </div>
  );
}
export default TopBar;
