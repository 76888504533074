import { Link } from "react-router-dom";

function Logo() {
  return (
    <Link to="/" className="logo" aria-label="Logo de Daniel Vargas">
      <span>DʌNIEL VʌRGʌS</span>
    </Link>
  );
}
export default Logo;
